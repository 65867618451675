html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  font-size: 1rem;
}
body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Helvetica", serif;
  font-weight: 300;
  line-height: 1.5em;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit;
}
h1,
h2,
h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}
h4,
h5,
h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}
h1 {
  font-size: 3.3125rem;
  line-height: 1.15em;
}
h2 {
  font-size: 2.25rem;
  line-height: 1.5em;
}
h3 {
  font-size: 1.5625rem;
  line-height: 1.4em;
}
h4 {
  font-size: 1.125rem;
  line-height: 1.5em;
}
h5 {
  font-size: 1.0625rem;
  line-height: 1.55em;
}
h6 {
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 500;
}
p {
  font-size: 14px;
  margin: 0 0 10px;
  line-height: 1.5em;
}
html {
  font-family: "Helvetica", serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}
body {
  background-color: #1c1d1e;
  color: $black-color;
  margin: 0;
  font-size: 1rem;
  text-align: left;
}

legend {
  border-bottom: 0;
}

// Prevent highlight on mobile
* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  &:focus {
    outline: 0;
  }
}

a {
  color: $link-color;
  text-decoration: none;
  background-color: transparent;
  &:hover,
  &:focus {
    color: darken($link-color, 5%);
    text-decoration: none;
  }
}

label {
  font-size: 14px;
  line-height: 1.42857;
  color: $checkboxes-text-color;
  font-weight: 400;
}
small {
  font-size: 75%;
  color: #777;
  font-weight: 400;
}
img {
  vertical-align: middle;
  border-style: none;
}
form {
  margin-bottom: 1.125rem;
}
#Independent {
  font-family: "Helvetica" !important;
}
#Dependent {
  font-family: "Helvetica" !important;
}
.MuiAutocomplete-option {
  font-family: "Helvetica" !important;
}
.MuiButton-root {
  font-family: "Helvetica" !important;
}
.MuiFormLabel-root {
  font-family: "Helvetica" !important;
}
.MuiTypography-h6 {
  font-family: "Helvetica" !important;
}
.MuiDialog-paper {
  border-color: black;
  border-width: "3px";
  border-style: solid;
}

// Here you can add other styles
.marginleft{

  margin-left: 1075px;
}
.MarginPoolsTeplateButton{
  margin-left: 15px;
  margin-bottom: 15px;


}
.MarginPoolsCinputButton1{
  margin-left: 22px;
  margin-bottom: 15px;
  margin-top:10px;


}
.MarginPoolsCinputButton{
  margin-left: 70px;
  margin-bottom: 15px;
  margin-top:22px;


}
.MarginDropTeplateButton{
  margin-left: 5px;
  margin-top:10px;


}
.MarginDropTeplateButton1{
  margin-left: 5px;
  margin-top:10px;



}
.MarginDropTeplateButton1{
  margin-left: 10px;
  margin-top:10px;



}
.MarginDropTeplateButton3{
  margin-left: 37px;
  margin-top:20px;
  margin-bottom:30px



}
.MarginDropTeplateButton4{
  margin-left: 37px;
  margin-top:50px;
  margin-bottom:30px



}
.ti{
  color: blue;
  font-size: large;
  margin-left: 15px;
}
.tableBord{

  border-left-color: white;
  border-left-style: groove;
  border-left-width: thin;




}

.tableBord1{

  border-left-color: lightgrey;
  border-left-style: groove;
  border-left-width: thin;
  border-bottom-color: snow;
  background-color: snow;
  border-bottom-width: thin;

  border-bottom-style: groove;
}
.PrintForm{
  display: flex !important;
  justify-content: center !important;
}
.Language{
  margin-bottom:30px
}
.Telephone{

  margin-top:15px;
  font-family: Helvetica;
  font-weight: bold;
  color: white;


}
.HomeBlack{
  background-color: #1c1d1e;
  color: white;
  padding-top: 20px;
  @media (min-width: 576px) {
    padding-top: 0px;
  };
}

.Buttons{
  font-size: medium;
  color: black;
  font-weight: bold;
  font-family: Helvetica;
  margin-bottom: 30 px;
}

.title_font {
  font-size: large;
  @media (min-width: 340px) {
    font-size: x-large !important;
  };
  @media (min-width: 576px) {
    font-size: xx-large !important;
  };
  // @media (min-width: 768px) {
  //   height: 550px !important;
  // };
  // @media (min-width: 992px) {
  //   height: 550px !important;
  // };
  // @media (min-width: 1200px) {
  //   height: 650px !important;
  // };

}
.sub_title_font {
  font-size: xx-small;
  @media (min-width: 340px) {
    font-size: x-small !important;
  };
  @media (min-width: 576px) {
    font-size: small !important;
  };
}

.slick-initialized .slick-track {
  display: flex;
  align-items: center;
}

.CarusselImage{
  margin-left: 40% ;
}
.slick-next::before {
  color: black !important;
}
.slick-prev::before {
  color: black !important;
  margin-left: -30%;
}

.darkgreyBackground{
  background-color: #5c5c5c;
}
.title1{
  margin-top: 30px;
  font-size: large;
  font-weight: bold;
  font-family: Helvetica;
  position: center;
  text-align: center;
}

.text1{
  text-align: center;
}



.WhiteBackground{
  background-color: white ;
  text-align: "left";
  padding: 0px !important;
  margin: 40px 10px 15px !important;

  // @media (min-width: 576px) {
  //   height: 550px !important;
  // };
  // @media (min-width: 768px) {
  //   height: 550px !important;
  // };
  // @media (min-width: 992px) {
  //   height: 550px !important;
  // };
  // @media (min-width: 1200px) {
  //   height: 650px !important;
  // };
}


.text2{
  margin: 20px;
  font-size: small;
  height: 130px;
}

.title2{
  font-weight: bolder;
  margin: 20px 20px 0px;
  font-size: larger;
  height: 70px;
}

.patricia{
  font-weight: bold;
  font-size: small;
  margin-left: 15px;
}

.MuiTypography-body1{
  font-size: small !important;
}
.MuiInputLabel-formControl {
  font-size: small !important;
}

.BlackBackground{
  background-color: black;


}
.FooterLetter{
  font-size: medium;
  color: white;
  margin-top: 5px;
  font-family: Helvetica;
  margin-bottom: 30 px;
}
.Email{

  margin-top: 5px;

}
.lineClass{
  border-top: grey;
  border-top-style: groove;
}
.FooterRights{
  font-size: small;
  color: grey;
}
.WhiteBackground1{
  background-color: white ;
  border-radius: 10px;
  padding: 20px;
}

.testimonial{
  font-size: x-small;
  display: flex;
  align-items: center;
  height: 120px;
  @media (min-width: 600px) {
    height: 300px !important;
  };
  @media (min-width: 784px) {
    height: 150px !important;
  };
  @media (min-width: 1200px) {
    height: 100px !important;
  };
}

.testimonial_name{
  height: 30px;
  @media (min-width: 600px) {
    height: 200px !important;
  };
  @media (min-width: 784px) {
    height: 70px !important;
  };
  @media (min-width: 960px) {
    height: 50px !important;
  };
}

.theFooterText .MuiFilledInput-underline:after {
  border-bottom: none !important;
}


.theFooterText .MuiFilledInput-adornedEnd {
  padding-right: 0px;
}

.theFooterText .MuiFilledInput-inputAdornedEnd {
  padding: inherit;
  padding-left: 15px;
  font-size: small;
}
.MuiButton-root{
  text-transform: none !important;
}



.Inventorytitle{
  margin-left: 150px;
  font-size:x-large;
  font-family:Helvetica;
  font-weight: bold;
  margin-top: 40px;
}
.InventoryBase{
  margin-left: 150px;
  margin-top: 40px;
  margin-bottom:50px;
  margin-right:100px;
}
.title3{
  font-size: medium;
  font-weight: bold;
  font-family: Helvetica;

}
.AboutUsTitle{
  font-size: xx-large;
  font-family: Helvetica;
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: bolder;
}
.AboutUsTitle1{
  font-size: larger;
  font-family: Helvetica;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 10px;
}
 .Contract{
   margin-top: 30px;
 }
.Contract1{
  margin-top: 30px;
  margin-left: 125px;
}
.Home45Words{
  margin-left: 125px;
  font-family: Helvetica;
  margin-top: 25px;
  color: white;
  margin-bottom: 35px;


}
.ContractUsTitle{
  margin-left: 105px;
  font-family: Helvetica;
  margin-top: 25px;
  color: black;
  margin-bottom: 35px;
  font-size: x-large;
  font-weight: bold;


}
.ContractUsTitle2{
  margin-left: 105px;
  font-family: Helvetica;



}
.ContractUsTitle3{
  margin-left: 40px;
  font-family: Helvetica;
  margin-top: 25px;
  color: black;
  margin-bottom: 35px;
  font-size: x-large;
  font-weight: bold;


}
.ContractUsTitle5{
  margin-left: 40px;
  font-family: Helvetica;
  color: dimgrey;
  margin-top: -15px;



}

.ContractUsTitle4{
  margin-left: 40px;
  font-family: Helvetica;
  color: dimgrey;

}
.ContractUsTitle7{
  margin-left: 40px;
  margin-top: 20px;
  text-align: center;
  font-family: Helvetica;
  color: white;
  font-size: xx-large;
  font-weight: bold;
}
.ContractUsTitle8{
  margin-left: 40px;
  margin-top: 20px;
  font-size: large;
  text-align: center;
  font-family: Helvetica;
  color: dimgrey;

  font-weight: bold;
}
